<template>
  <div style="display: block !important">
    <v-expansion-panels
      v-model="panel"
      width="100%"
      style="display: block !important"
    >
      <draggable v-model="itemList" :options="{ disabled: disableDraggable }">
        <v-expansion-panel
          class="mb-5"
          v-for="(item, index) in itemList"
          :key="item.id"
        >
          <v-expansion-panel-header
            expand-icon=""
            class="pa-7 pa-lg-auto text-left"
            style="width: 100%"
            @click="isShowContent = true"
          >
            <v-row
              justify="start"
              align="center"
              :style="
                $vuetify.breakpoint.xs
                  ? 'position: relative;'
                  : 'position: relative; padding-right: 45px;'
              "
            >
              <div style="width: 20px">
                <v-icon
                  class="mr-3"
                  style=""
                  color="#90E0CA"
                  v-if="index !== activeItem && !isShowContent"
                >
                  mdi-plus
                </v-icon>
                <v-icon
                  class="mr-3"
                  style=""
                  color="#90E0CA"
                  v-if="index !== activeItem && isShowContent"
                >
                  mdi-plus
                </v-icon>
                <v-icon
                  class="mr-3"
                  style="color: rgba(230, 32, 118, 0.5)"
                  v-if="active && index == activeItem && isShowContent"
                >
                  mdi-minus
                </v-icon>
              </div>
              <v-col
                :cols="admin ? '9' : '10'"
                xl="11"
                lg="11"
                md="11"
                sm="8"
                style="text-align: justify"
              >
                <span v-if="loggedUser">
                  {{
                    loggedUser.locale == "en" ? item.title_en : item.title_es
                  }}
                </span>
                <span v-else>
                  {{ user.locale == "en" ? item.title_en : item.title_es }}
                </span>
              </v-col>
              <v-icon
                :style="
                  $vuetify.breakpoint.xs
                    ? 'position: absolute; right: 0; top: 0; bottom: 0;'
                    : 'position: absolute; right: 0; top: 0px;z-index: 100;'
                "
                @click="(isShowContent = false), editItem(item.id)"
                color="#E62076"
                v-if="isAdmin"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                :style="
                  $vuetify.breakpoint.xs
                    ? 'position: absolute; right: 25px; top: 0; bottom: 0;'
                    : 'position: absolute; right: 25px; top: 0px;'
                "
                v-if="isAdmin"
                @click="(isShowContent = false), deleteItem(item.id)"
                color="#EB5757"
                class="ml-2"
              >
                mdi-delete
              </v-icon>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content v-if="loggedUser && isShowContent">
            <div
              v-html="item.description_en"
              v-if="loggedUser.locale == 'en'"
            ></div>
            <div
              v-html="item.description_es"
              v-if="loggedUser.locale == 'es'"
            ></div>
          </v-expansion-panel-content>
          <v-expansion-panel-content v-else-if="isShowContent">
            <div v-html="item.description_en" v-if="user.locale == 'en'"></div>
            <div v-html="item.description_es" v-if="user.locale == 'es'"></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </draggable>
    </v-expansion-panels>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
export default {
  components: {
    draggable,
  },
  data: () => ({
    panel: "",
    active: false,
    activeItem: "",
    disableDraggable: true,
    newList: true,
    isShowContent: true,
    itemList: [],
  }),
  props: {
    isAdmin: {
      require: true,
    },
    FAQList: {
      require: true,
    },
  },
  mounted() {
    this.itemList = this.FAQList;
    if (this.loggedUser && this.loggedUser.admin) {
      this.admin = true;
      this.disableDraggable = false;
    }
  },
  methods: {
    editItem(id) {
      this.$emit("edit", id);
      this.active = false;
      setTimeout(() => {
        this.panel = "";
        this.activeItem = "";
        this.isShowContent = false;
      }, 0.1);
    },
    deleteItem(id) {
      this.$emit("delete", id);
      this.active = false;
      setTimeout(() => {
        this.panel = "";
        this.activeItem = "";
        this.isShowContent = false;
      }, 0.1);
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
  },
  watch: {
    panel: {
      deep: true,
      handler() {
        this.activeItem = this.panel;
        this.active = true;
      },
    },
    itemList: {
      deep: true,
      handler() {
        if (!this.newList) {
          this.$emit("updatedList", this.itemList);
        } else {
          this.newList = false;
        }
      },
    },
  },
};
</script>

<style></style>
