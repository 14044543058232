<template>
  <v-snackbar top v-model="snackbar" color="success">
    <v-row align="center">
      <v-icon class="mx-3">mdi-check</v-icon>
      <v-col v-if="boldText">
        <span class="font-weight-bold"> {{ snackbarText | localize }}</span>
      </v-col>
      <v-col v-else>
        <span> {{ snackbarText | localize }}</span>
      </v-col>
    </v-row></v-snackbar
  >
</template>

<script>
export default {
  props: {
    snackbar: {
      require: true,
    },
    snackbarText: {
      require: true,
    },
    boldText: {
      require: false,
    }
  },
};
</script>

<style>
</style>