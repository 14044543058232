<template>
  <v-dialog
    :retain-focus="false"
    persistent
    v-model="visibility"
    max-width="900px"
  >
    <v-card style="text-justify: center">
      <v-card-title v-if="!isEdit">{{
        "faq_title_add_item" | localize
      }}</v-card-title>
      <v-card-title v-else>{{
        "faq_update_item_label" | localize
      }}</v-card-title>
      <v-stepper v-model="steps">
        <v-stepper-header>
          <v-stepper-step step="1" :complete="steps > 1" color="#E62076">{{
            "attraction_label_information_en" | localize
          }}</v-stepper-step>
          <v-divider class="px-10" />
          <v-stepper-step step="2" :complete="steps > 2" color="#E62076">{{
            "attraction_label_information_es" | localize
          }}</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content class="px-5 mt-3" step="1">
            <v-text-field
              outlined
              dense
              placeholder="Type here.."
              class="rounded-lg mt-2"
              color="#E62076"
              v-model="faqItem.title_en"
              :error-messages="titleENError | localize"
              @blur="$v.faqItem.title_en.$touch()"
            >
              <template v-slot:label>
                <span
                  >{{ "attractions_placeholder_title" | localize }} EN
                  <span class="req">*</span>
                </span>
              </template>
            </v-text-field>
            <div
              :style="
                textENError.length
                  ? 'border: 2px solid #ff5252; border-radius: 3px'
                  : ''
              "
            >
              <ckeditor
                v-if="upload"
                :editor="editor"
                v-model="faqItem.description_en"
                :config="editorConfig"
              ></ckeditor>
            </div>
            <span class="ml-3 error--text" style="font-size: 13px">{{
              textENError[0] | localize
            }}</span>
            <v-row justify="end" class="mb-5 mr-1 mt-3">
              <v-btn text @click="$emit('close')">
                {{ "button_cancel" | localize }}
              </v-btn>
              <v-btn
                color="#E62076"
                dark
                @click="
                  !$v.faqItem.title_en.$invalid &&
                  !$v.faqItem.description_en.$invalid
                    ? ((steps = 2), $v.$reset())
                    : $v.$touch()
                "
              >
                {{ "faq_button_continue" | localize }}
              </v-btn>
            </v-row>
          </v-stepper-content>
          <v-stepper-content class="px-5 mt-3" step="2">
            <v-text-field
              outlined
              dense
              placeholder="Type here.."
              label="Title ES"
              class="rounded-lg mt-2"
              color="#E62076"
              v-model="faqItem.title_es"
              :error-messages="titleESError | localize"
              @blur="$v.faqItem.title_es.$touch()"
            >
              <template v-slot:label>
                <span
                  >{{ "attractions_placeholder_title" | localize }} ES
                  <span class="req">*</span>
                </span>
              </template>
            </v-text-field>
            <div
              :style="
                textESError.length
                  ? 'border: 2px solid #ff5252; border-radius: 3px'
                  : ''
              "
            >
              <ckeditor
                v-if="upload"
                :editor="editor"
                v-model="faqItem.description_es"
                :config="editorConfig"
              ></ckeditor>
            </div>
            <span class="ml-3 error--text" style="font-size: 13px">{{
              textESError[0] | localize
            }}</span>
            <v-row justify="end" class="mb-5 mr-1">
              <v-btn text @click="$emit('close')">
                {{ "button_cancel" | localize }}
              </v-btn>
              <v-btn
                color="#E62076"
                dark
                v-if="!isEdit"
                @click="
                  !$v.faqItem.title_es.$invalid &&
                  !$v.faqItem.description_es.$invalid
                    ? createNewFAQItem()
                    : $v.$touch()
                "
              >
                {{ "button_save" | localize }}
              </v-btn>
              <v-btn
                v-else
                color="#E62076"
                dark
                @click="
                  !$v.faqItem.title_es.$invalid &&
                  !$v.faqItem.description_es.$invalid
                    ? updateFAQItem(faqItem.id)
                    : $v.$touch()
                "
              >
                {{ "button_update" | localize }}
              </v-btn>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>

<script>
import Editor from "../../../ckeditor5/build/ckeditor";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
export default {
  mixins: [validationMixin],
  data: () => ({
    editor: Editor,
    editorConfig: {},
    faqItem: {},
    steps: 1,
    upload: false,
    userLocale: "",
  }),
  props: {
    visible: {
      require: true,
    },
    isEdit: {
      require: true,
    },
    item: {
      require: true,
    },
  },
  validations: {
    faqItem: {
      title_en: {
        required,
      },
      description_en: {
        required,
      },
      title_es: {
        required,
      },
      description_es: {
        required,
      },
    },
  },
  mounted() {
    this.userLocale = this.loggedUser
      ? this.loggedUser.locale
      : this.user.locale;
    setTimeout(() => {
      this.upload = true;
    }, 120);
    this.isEdit ? (this.faqItem = this.item) : "";
    this.editorConfig.placeholder =
      this.userLocale == "en" ? "Description" : "Descripción";
    this.editorConfig.language = this.userLocale == "en" ? "en" : "es";
  },
  methods: {
    async getItem() {
      console.log("getItem");
    },
    createNewFAQItem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit("create", this.faqItem);
      }
    },
    updateFAQItem(id) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit("update", id, this.faqItem);
      }
    },
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      },
    },
    titleENError() {
      const errors = [];
      if (!this.$v.faqItem.title_en.$dirty) {
        return errors;
      }
      !this.$v.faqItem.title_en.required &&
        errors.push("attractions_validation_title_required");
      return errors;
    },
    textENError() {
      const errors = [];
      if (!this.$v.faqItem.description_en.$dirty) {
        return errors;
      }
      !this.$v.faqItem.description_en.required &&
        errors.push("hoozie_rewards_text_validation");
      return errors;
    },
    titleESError() {
      const errors = [];
      if (!this.$v.faqItem.title_es.$dirty) {
        return errors;
      }
      !this.$v.faqItem.title_es.required &&
        errors.push("attractions_validation_title_required");
      return errors;
    },
    textESError() {
      const errors = [];
      if (!this.$v.faqItem.description_es.$dirty) {
        return errors;
      }
      !this.$v.faqItem.description_es.required &&
        errors.push("hoozie_rewards_text_validation");
      return errors;
    },
  },
};
</script>

<style>
.req {
  color: red;
}
.ck.ck-editor__editable > .ck-placeholder::after {
  content: "*";
  color: red;
  margin-left: 95px;
}
.ck.ck-editor__editable > .ck-placeholder {
  display: flex;
}
</style>