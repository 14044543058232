var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"retain-focus":false,"persistent":"","max-width":"900px"},model:{value:(_vm.visibility),callback:function ($$v) {_vm.visibility=$$v},expression:"visibility"}},[_c('v-card',{staticStyle:{"text-justify":"center"}},[(!_vm.isEdit)?_c('v-card-title',[_vm._v(_vm._s(_vm._f("localize")("faq_title_add_item")))]):_c('v-card-title',[_vm._v(_vm._s(_vm._f("localize")("faq_update_item_label")))]),_c('v-stepper',{model:{value:(_vm.steps),callback:function ($$v) {_vm.steps=$$v},expression:"steps"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"step":"1","complete":_vm.steps > 1,"color":"#E62076"}},[_vm._v(_vm._s(_vm._f("localize")("attraction_label_information_en")))]),_c('v-divider',{staticClass:"px-10"}),_c('v-stepper-step',{attrs:{"step":"2","complete":_vm.steps > 2,"color":"#E62076"}},[_vm._v(_vm._s(_vm._f("localize")("attraction_label_information_es")))])],1),_c('v-stepper-items',[_c('v-stepper-content',{staticClass:"px-5 mt-3",attrs:{"step":"1"}},[_c('v-text-field',{staticClass:"rounded-lg mt-2",attrs:{"outlined":"","dense":"","placeholder":"Type here..","color":"#E62076","error-messages":_vm._f("localize")(_vm.titleENError)},on:{"blur":function($event){return _vm.$v.faqItem.title_en.$touch()}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm._f("localize")("attractions_placeholder_title"))+" EN "),_c('span',{staticClass:"req"},[_vm._v("*")])])]},proxy:true}]),model:{value:(_vm.faqItem.title_en),callback:function ($$v) {_vm.$set(_vm.faqItem, "title_en", $$v)},expression:"faqItem.title_en"}}),_c('div',{style:(_vm.textENError.length
                ? 'border: 2px solid #ff5252; border-radius: 3px'
                : '')},[(_vm.upload)?_c('ckeditor',{attrs:{"editor":_vm.editor,"config":_vm.editorConfig},model:{value:(_vm.faqItem.description_en),callback:function ($$v) {_vm.$set(_vm.faqItem, "description_en", $$v)},expression:"faqItem.description_en"}}):_vm._e()],1),_c('span',{staticClass:"ml-3 error--text",staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm._f("localize")(_vm.textENError[0])))]),_c('v-row',{staticClass:"mb-5 mr-1 mt-3",attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm._f("localize")("button_cancel"))+" ")]),_c('v-btn',{attrs:{"color":"#E62076","dark":""},on:{"click":function($event){!_vm.$v.faqItem.title_en.$invalid &&
                !_vm.$v.faqItem.description_en.$invalid
                  ? ((_vm.steps = 2), _vm.$v.$reset())
                  : _vm.$v.$touch()}}},[_vm._v(" "+_vm._s(_vm._f("localize")("faq_button_continue"))+" ")])],1)],1),_c('v-stepper-content',{staticClass:"px-5 mt-3",attrs:{"step":"2"}},[_c('v-text-field',{staticClass:"rounded-lg mt-2",attrs:{"outlined":"","dense":"","placeholder":"Type here..","label":"Title ES","color":"#E62076","error-messages":_vm._f("localize")(_vm.titleESError)},on:{"blur":function($event){return _vm.$v.faqItem.title_es.$touch()}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm._f("localize")("attractions_placeholder_title"))+" ES "),_c('span',{staticClass:"req"},[_vm._v("*")])])]},proxy:true}]),model:{value:(_vm.faqItem.title_es),callback:function ($$v) {_vm.$set(_vm.faqItem, "title_es", $$v)},expression:"faqItem.title_es"}}),_c('div',{style:(_vm.textESError.length
                ? 'border: 2px solid #ff5252; border-radius: 3px'
                : '')},[(_vm.upload)?_c('ckeditor',{attrs:{"editor":_vm.editor,"config":_vm.editorConfig},model:{value:(_vm.faqItem.description_es),callback:function ($$v) {_vm.$set(_vm.faqItem, "description_es", $$v)},expression:"faqItem.description_es"}}):_vm._e()],1),_c('span',{staticClass:"ml-3 error--text",staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm._f("localize")(_vm.textESError[0])))]),_c('v-row',{staticClass:"mb-5 mr-1",attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm._f("localize")("button_cancel"))+" ")]),(!_vm.isEdit)?_c('v-btn',{attrs:{"color":"#E62076","dark":""},on:{"click":function($event){!_vm.$v.faqItem.title_es.$invalid &&
                !_vm.$v.faqItem.description_es.$invalid
                  ? _vm.createNewFAQItem()
                  : _vm.$v.$touch()}}},[_vm._v(" "+_vm._s(_vm._f("localize")("button_save"))+" ")]):_c('v-btn',{attrs:{"color":"#E62076","dark":""},on:{"click":function($event){!_vm.$v.faqItem.title_es.$invalid &&
                !_vm.$v.faqItem.description_es.$invalid
                  ? _vm.updateFAQItem(_vm.faqItem.id)
                  : _vm.$v.$touch()}}},[_vm._v(" "+_vm._s(_vm._f("localize")("button_update"))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }