<template>
  <div class="mx-4" style="position: relative">
    <snackbar-success :snackbar="snackbar" :snackbarText="snackbarText" />
    <v-row class="mx-1" style="position: relative" align="center">
      <ComponentTitle title="header_faq_part1" />
      <v-btn
        v-if="admin"
        fab
        color="#E62076"
        right
        dark
        small
        center
        absolute
        @click="(showModal = true), (snackbar = false)"
        class="mb-1"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-row>
    <v-row>
      <v-col v-if="showLoader" cols="12" xl="8" lg="8" md="8" sm="12">
        <Loader />
      </v-col>
      <v-col v-else cols="12" xl="8" lg="8" md="8" sm="12">
        <FAQItems
          @edit="getItem"
          @delete="confirmDeleteItem"
          :isAdmin="admin"
          :FAQList="FAQList"
          @updatedList="updateList"
          :key="faqItemsKey"
        />
      </v-col>
      <v-col class="col-12 col-lg-4">
        <FAQMessageForm
          class="ml-0 ml-lg-5"
          @sendMessage="sendMessage"
        />
      </v-col>
    </v-row>
    <AddFAQModal
      v-if="showModal"
      :visible="showModal"
      @close="(showModal = false), (isEdit = false)"
      @create="createNewFAQItem"
      @update="updateItem"
      :isEdit="isEdit"
      :item="FAQItem"
    />
    <confirm-delete-modal
      v-if="showDeleteModal"
      :visible="showDeleteModal"
      @close="showDeleteModal = false"
      @deleteItem="deleteItem"
    />
  </div>
</template>

<script>
import FAQItems from "./FAQItems.vue";
import FAQMessageForm from "./FAQMessageForm.vue";
import ComponentTitle from "../UI/ComponentTitle.vue";
import snackbarSuccess from "@/components/UI/snackbarSuccess";
import AddFAQModal from "@/components/FAQ/AddFAQModal";
import FAQService from "@/requests/FAQ/FAQService.js";
import Loader from "../UI/Loader.vue";
import { mapGetters } from "vuex";
import confirmDeleteModal from "./confirmDeleteModal.vue";
export default {
  components: {
    FAQItems,
    FAQMessageForm,
    ComponentTitle,
    AddFAQModal,
    snackbarSuccess,
    Loader,
    confirmDeleteModal,
  },
  data: () => ({
    admin: false,
    showModal: false,
    snackbar: false,
    snackbarText: "",
    isEdit: false,
    showLoader: true,
    showDeleteModal: false,
    itemDeelteId: null,
    FAQList: [],
    FAQItem: {},
    faqItemsKey: 0,
  }),
  mounted() {
    window.scrollTo(0,0)
    this.getItems();
    if (this.loggedUser && this.loggedUser.admin) {
      this.admin = true;
    }
  },
  methods: {
    async createNewFAQItem(item) {
      let response = await FAQService.addNewItem({
        ...item,
        position: this.FAQList.length - 1,
      });
      if (response.status == "success") {
        this.showLoader = true;
        this.showModal = false;
        this.snackbarText = "faq_item_created";
        this.snackbar = true;
        this.getItems();
      }
    },
    async getItems() {
      let response = await FAQService.getFAQList();
      this.FAQList = response.result.sort((a, b) => a.position - b.position);
      this.showLoader = false;
      this.faqItemsKey++;
    },
    async getItem(id) {
      this.snackbar = false;
      let response = await FAQService.getItem(id);
      this.FAQItem = response.result;
      this.isEdit = true;
      this.showModal = true;
    },
    async updateItem(id, item) {
      let response = await FAQService.updateItem(id, item);
      if (response.status == "success") {
        this.showLoader = true;
        this.showModal = false;
        this.snackbarText = "faq_item_updated";
        this.snackbar = true;
        this.isEdit = false;
        this.getItems();
      }
    },
    confirmDeleteItem(id) {
      this.snackbar = false;
      this.showDeleteModal = true;
      this.itemDeelteId = id;
    },
    async deleteItem() {
      await FAQService.deleteItem(this.itemDeelteId);
      this.snackbarText = "faq_item_deleted";
      this.snackbar = true;
      this.getItems();
      this.showDeleteModal = false;
    },
    async updateList(list) {
      list.forEach(
        async (item, index) =>
          await FAQService.updateItem(item.id, {
            title_en: item.title_en,
            title_es: item.title_es,
            description_en: item.description_en,
            desctiption_es: item.description_es,
            position: index,
          })
      );
    },
    async sendMessage(form){
      await FAQService.sendMessage(form).then((response) => {
        console.log(response)
        if(response.status == 'success'){
        this.snackbarText = 'faq_notification_message_sent'; 
        this.snackbar = true;
      }
      })
    }
  },
  computed: {
    ...mapGetters(["loggedUser"]),
  },
};
</script>

<style>
</style>