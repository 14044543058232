<template>
  <v-dialog width="400px" v-model="visibility">
    <v-card>
        <v-card-title>Delete FAQ item</v-card-title>
        <v-card-text>
            <p style="font-size: 16px;">Are you sure you want to delete a FAQ item?</p>
        </v-card-text>
        <v-card-actions>
            <v-row no-gutters justify="end">
                <v-btn text @click="$emit('close')">{{'button_no' | localize}}</v-btn>
                <v-btn color="#E62076" dark class="ml-3" @click="$emit('deleteItem')">{{'button_yes' | localize}}</v-btn>
            </v-row>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            require: true,
        }
    },
    computed: {
        visibility: {
            get(){
                return this.visible;
            },
            set(){
                this.$emit('close')
            }
        }
    }
}
</script>

<style>

</style>