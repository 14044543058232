<template>
  <div>
    <v-card color="#6ABAA4" width="100%" class="rounded-lg">
      <v-card-title class="white--text">{{
        "faq_contact_us_label" | localize
      }}</v-card-title>
      <v-col class="py-0">
        <v-text-field
          class="rounded-lg"
          outlined
          background-color="white"
          dense
          v-model="message.name"
          :error-messages="nameError"
          :color="nameError.lenght ? '' : '#6ABAA4'"
          @blur="$v.message.name.$touch()"
        >
          <template v-slot:label>
            <span>
              {{ "faq_placeholder_name" | localize }}
              <span class="req">*</span>
            </span>
          </template>
        </v-text-field>
        <v-text-field
          class="rounded-lg"
          outlined
          type="email"
          autocomplete="email"
          background-color="white"
          dense
          v-model="message.email"
          :error-messages="emailError"
          :color="emailError.lenght ? '' : '#6ABAA4'"
          @blur="$v.message.email.$touch()"
        >
          <template v-slot:label>
            <span>
              {{ "faq_placeholder_email" | localize }}
              <span class="req">*</span>
            </span>
          </template></v-text-field
        >
        <v-textarea
          class="rounded-lg"
          outlined
          background-color="white"
          dense
          auto-grow
          v-model="message.text"
          :error-messages="textError"
          :color="textError.lenght ? '' : '#6ABAA4'"
          @blur="$v.message.text.$touch()"
        >
          <template v-slot:label>
            <span>
              {{ "faq_placeholder_message" | localize }}
              <span class="req">*</span>
            </span>
          </template></v-textarea
        >
      </v-col>
    </v-card>
    <v-btn
      width="100%"
      height="45px"
      color="#E62076"
      class="white--text mt-2 rounded-lg"
      @click="sendMessage"
    >
      {{ "faq_button_send_message" | localize }}
    </v-btn>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  name: "FAQMessageForm",
  data: () => ({
    message: {},
  }),
  validations: {
    message: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      text: {
        required,
      },
    },
  },
  methods: {
    sendMessage() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$v.$reset();
        this.$emit("sendMessage", this.message);
        this.message = {};
      }
    },
  },
  computed: {
    nameError() {
      const errors = [];
      if (!this.$v.message.name.$dirty) {
        return errors;
      }
      !this.$v.message.name.required && errors.push("");
      return errors;
    },
    emailError() {
      const errors = [];
      if (!this.$v.message.email.$dirty) {
        return errors;
      }
      !this.$v.message.email.email && errors.push("");
      !this.$v.message.email.required && errors.push("");
      return errors;
    },
    textError() {
      const errors = [];
      if (!this.$v.message.text.$dirty) {
        return errors;
      }
      !this.$v.message.text.required && errors.push("");
      return errors;
    },
  },
};
</script>

<style>
</style>