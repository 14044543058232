<template>
  <v-main>
    <v-container>
      <FAQComponent />
    </v-container>
  </v-main>
</template>

<script>
import FAQComponent from "../components/FAQ/FAQComponent.vue";
export default {
  components: {
    FAQComponent,
  },
};
</script>

<style>
</style>