import store from '@/store';
import requestService from '../requestService';

export default {
    async getFAQList() {
        const response = await requestService.get(`faqs`, {
            headers: {
                'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
            }
        })
        return response?.data
    },
    async addNewItem(form) {
        const response = await requestService.post(`faqs`, form, {
            headers: {
                Authorization: `Bearer ${store.getters.loggedUser.token}`,
                'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
            }
        })
        return response?.data
    },
    async deleteItem(id) {
        const response = await requestService.delete(`faqs/${id}`, {
            headers: {
                Authorization: `Bearer ${store.getters.loggedUser.token}`,
                'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
            }
        })
        return response?.data
    },
    async getItem(id) {
        const response = await requestService.get(`faqs/${id}`)
        return response?.data
    },
    async updateItem(id, form) {
        const response = await requestService.put(`faqs/${id}`, form, {
            headers: {
                Authorization: `Bearer ${store.getters.loggedUser.token}`,
                'x-citypass-key': `${process.env.VUE_APP_X_API_KEY}`
            }
        })
        return response?.data
    },
    async sendMessage(form) {
        const response = await requestService.post(`contacts`, form);
        return response?.data
    }
}